* {
  line-height: 1.5;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  --primaryColor: #2770de;
  --primaryColor1: #161546;
  --primaryColorRGB: 4, 30, 65;
  --secondaryColor: #ff9900;
  --secondaryColorRGB: 255, 153, 0;
  --tertiarycolor: #f8f9fa;
  --quaternaryColor: #000;
  --dangerColor: #f00c0ccc;
  --box-shadow-selected: 0 3px 15px rgb(0 0 0 / 13%);
  --whiteBackground: #fff;
  --successColor: rgb(57, 175, 57);
  --lightSuccessColor: rgb(202, 218, 191);
  --lightSuccessColor1: rgb(231, 232, 216);
  --grayColor: #c4c4c4;
  --lightGrayColor: #c7c7c7;
  --extra-large-font: 20px;
  --large-font: 18px;
  --medium-font: 16px;
  --small-font: 14px;
  --extra-small-font: 12px;
  --more-extra-small-font: 10px;
  --purpleColor: #8d0cf0cc;

}

html,
body {
  max-width: 100vw;
  overflow: hidden;
}

.disable {
  color: var(--grayColor) !important;
}


.modal-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 32px;
}

.modal-heading h4 {
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px !important;
}

.modal-heading svg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: inherit;
}

.modal-body {
  height: calc(100% - 38px) !important;
}

.modal-sub-body {
  max-height: 300px;
  overflow: auto;
}

.modal-close-button {
  cursor: pointer;
}

#close-btn {
  background-color: var(--dangerColor);
  color: var(--tertiarycolor);
}

#primary-btn {
  background-color: var(--primaryColor);
  color: var(--tertiarycolor);
}

#submit-btn {
  background-color: var(--primaryColor);
  color: var(--tertiarycolor);
}

.background-primary {
  background-color: var(--primaryColor) !important;
}

.background-secondaryColor {
  background-color: var(--secondaryColor) !important;
}

.background-warning {
  background-color: var(--successColor) !important;
}

.background-danger {
  background-color: var(--dangerColor) !important;
}

main {
  width: 100%;
  background-color: #fff !important;
}

.download-button {
  background-color: var(--primaryColor) !important;
}

.container-100 {
  height: 100% !important;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
}

.layout-main-container {
  display: flex;
  height: 100vh;
}

.scroll-bar {
  overflow: auto;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

.form-field {
  flex: 2;
}

.output {
  margin-left: 3rem;
  flex: 1;
}

input {
  height: 35px;
  width: 100%;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

.services {
  display: flex;
  justify-content: space-between;
}

.first-division {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0.5rem 0;
  width: 100%;
}

button {
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  padding: 5px 10px;
  margin-top: 1px;
}

.first-division button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}

.second-division button {
  color: red;
  /* border: 1px solid red; */
}

/*table*/
.table {
  border-collapse: collapse;
  margin: 0px 0;
  font-size: 15px;
  min-width: 100%;
  border-radius: 5px 5px 0 0;
}

table thead tr {
  color: #fff;
  background: var(--primaryColor);
  text-align: left;
  font-weight: bold;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px;
}

.table tbody tr {
  border-bottom: 1px solid #ddd;
}

.table tbody tr:nth-of-type(even) {
  background: #f3f3f3;
}

.table tbody tr.active {
  font-weight: bold;
  color: var(--primaryColor);
}

.table button {
  padding: 6px 20px;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid var(--primaryColor); */
}

.table button:hover {
  background: var(--primaryColor);
  color: #fff;
  transition: 0.5rem;
}

.width-100 {
  width: 100% !important
}

.height-20 {
  height: 20px !important;
}

.height-25 {
  height: 25px !important;
}

.height-30 {
  height: 30px !important;
}

.height-35 {
  height: 35px !important;
}

.height-40 {
  height: 40px !important;
}

.flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

.flex-end-center {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.flex-start-center {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.flex-center-space-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.heading-center-space-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.heading-start-center {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.sub-container {
  /* border: 1px solid blueviolet; */
}

.cal-20 {
  height: calc(100% - 20px) !important;
}

.cal-25 {
  height: calc(100% - 25px) !important;
}

.cal-30 {
  height: calc(100% - 30px) !important;
}

.cal-35 {
  height: calc(100% - 35px) !important;
}

.cal-40 {
  height: calc(100% - 40px) !important;
}




.attendance {
  margin-top: 20px;
  text-transform: capitalize;
}

.main-container {
  width: 100%;
  padding: 0px 40px 40px 40px;
  padding-top: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.table thead {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
}

.leave-balance-show-container {
  color: var(--tertiarycolor);
  border-radius: 8px;
  margin-right: 65% !important;
  margin-top: 0px;
  padding: 4px;
  width: 32%;
  background-color: var(--successColor);
}

.leave-balance-show-container1 {
  color: var(--tertiarycolor);
  border-radius: 8px;
  margin-right: 65% !important;
  margin-top: 0px;
  padding: 4px;
  width: 30%;
  background-color: var(--dangerColor);
}

.css-8vnh0h-MuiPaper-root-MuiAlert-root {
  background-color: var(--primaryColor) !important;
  margin-top: 10%;
}

.employee-container {
  height: 100%;
}

.heading4 {
  display: flex;
  justify-content: space-between;
  height: 35px !important;
  align-items: center;
}

.employee-back-button-heading-container {
  display: flex;
  gap: 17px;
  height: 100%;
  align-items: center;
  padding-right: 40px;
  padding-top: 15px;
}

/*Holiday-css*/
.holiday-nodata-container {
  display: flex;
  margin-top: 85px;
}

.holiday-nodata-alert-icon {
  color: var(--dangerColor);
}

.holiday-nodata-container>div {
  margin-top: 7px;
}

.error-field {
  font-size: 10px;
  color: var(--dangerColor);
  font-weight: 600;
  height: 10px;
}

.modal-class {
  position: fixed;
  z-Index: 0;
}

.search-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  flex-shrink: 1;
}

.avatar {
  background-color: var(--primaryColor) !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
  margin-right: 3px !important;
}

.circular-add-button {
  color: var(--primaryColor);
  font-size: 200% !important;
  height: 30px !important;
}

.date-range-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: 5px
}

.css-k008qs header {
  background-color: #FFFFFF !important;
  color: var(--primaryColor);
}

.download-button-container {
  border-radius: 20px !important;
}

.button-style {
  padding: 9px;
  border-radius: 3px;
  background-color: var(--primaryColor);
}

.button-class {
  display: contents !important;
}

.button-class button {
  padding: 2px 10px !important;
  margin-bottom: 5px !important;
}

.button-class-top button {
  padding: 3px 18px !important;
}



.page-not-found {
  background-image: url('./assets/logo/404-page-not-found.svg');
  background-position: center;
  background-size: contain;
  /* or cover, depending on your preference */
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgb(216, 88, 88);
}

.page-not-found div {
  margin-top: 33%;
  font-size: 100%;
}

.cursor-pointer {
  cursor: pointer !important;
}

.ant-picker-calendar-full .ant-picker-calendar-date-content::-webkit-scrollbar,
.drawer-container::-webkit-scrollbar,
.sidebar-drawer-container::-webkit-scrollbar,
.leave-application-field-container::-webkit-scrollbar,
.ag-horizontal-left-spacer::-webkit-scrollbar,
.ag-horizontal-right-spacer::-webkit-scrollbar,
.reason-field-container::-webkit-scrollbar,
.scroll-bar::-webkit-scrollbar,
.modal-sub-body::-webkit-scrollbar,
.form-field-sub-container::-webkit-scrollbar,
.ag-body-vertical-scroll-viewport::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
.user-leave-details-main-container::-webkit-scrollbar,
.payroll-container::-webkit-scrollbar,
.sub-container-wrapper::-webkit-scrollbar,
.card-sub-body::-webkit-scrollbar,
.lop-sub-table-container::-webkit-scrollbar,
.team-create-form-container::-webkit-scrollbar,
.work-from-home-sub-container::-webkit-scrollbar,
.edit-field-container::-webkit-scrollbar,
.form-field-stack-container::-webkit-scrollbar,
.sub-from-container::-webkit-scrollbar,
.form-container-editing::-webkit-scrollbar,
.attendance-sub-container::-webkit-scrollbar {
  width: 6px !important;
  height: 6px;
}

/* Track */
.ant-picker-calendar-full .ant-picker-calendar-date-content::-webkit-scrollbar-track,
.drawer-container::-webkit-scrollbar-track,
.sidebar-drawer-container::-webkit-scrollbar-track,
.leave-application-field-container::-webkit-scrollbar-track,
.ag-horizontal-left-spacer::-webkit-scrollbar-track,
.ag-horizontal-right-spacer::-webkit-scrollbar-track,
.reason-field-container::-webkit-scrollbar-track,
.scroll-bar::-webkit-scrollbar-track,
.modal-sub-body::-webkit-scrollbar-track,
.form-field-sub-container::-webkit-scrollbar-track,
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-track,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
.user-leave-details-main-container::-webkit-scrollbar-track,
.payroll-container::-webkit-scrollbar-track,
.sub-container-wrapper::-webkit-scrollbar-track,
.card-sub-body::-webkit-scrollbar-track,
.lop-sub-table-container::-webkit-scrollbar-track,
.team-create-form-container::-webkit-scrollbar-track,
.work-from-home-sub-container::-webkit-scrollbar-track,
.edit-field-container::-webkit-scrollbar-track,
.form-field-stack-container::-webkit-scrollbar-track,
.sub-from-container::-webkit-scrollbar-track,
.form-container-editing::-webkit-scrollbar-track,
.attendance-sub-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle */
.ant-picker-calendar-full .ant-picker-calendar-date-content::-webkit-scrollbar-thumb,
.drawer-container::-webkit-scrollbar-thumb,
.sidebar-drawer-container::-webkit-scrollbar-thumb,
.leave-application-field-container::-webkit-scrollbar-thumb,
.ag-horizontal-left-spacer::-webkit-scrollbar-thumb,
.form-field-sub-container::-webkit-scrollbar-thumb,
.ag-horizontal-right-spacer::-webkit-scrollbar-thumb,
.reason-field-container::-webkit-scrollbar-thumb,
.scroll-bar::-webkit-scrollbar-thumb,
.modal-sub-body::-webkit-scrollbar-thumb,
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,
.user-leave-details-main-container::-webkit-scrollbar-thumb,
.payroll-container::-webkit-scrollbar-thumb,
.sub-container-wrapper::-webkit-scrollbar-thumb,
.card-sub-body::-webkit-scrollbar-thumb,
.lop-sub-table-container::-webkit-scrollbar-thumb,
.team-create-form-container::-webkit-scrollbar-thumb,
.work-from-home-sub-container::-webkit-scrollbar-thumb,
.edit-field-container::-webkit-scrollbar-thumb,
.form-field-stack-container::-webkit-scrollbar-thumb,
.sub-from-container::-webkit-scrollbar-thumb,
.form-container-editing::-webkit-scrollbar-thumb,
.attendance-sub-container::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
  border-radius: 10px;
}


/* Handle on hover */
.ant-picker-calendar-full .ant-picker-calendar-date-content::-webkit-scrollbar-thumb:hover,
.drawer-container::-webkit-scrollbar-thumb:hover,
.sidebar-drawer-container::-webkit-scrollbar-thumb:hover,
.leave-application-field-container::-webkit-scrollbar-thumb:hover,
.ag-horizontal-left-spacer::-webkit-scrollbar-thumb:hover,
.ag-horizontal-right-spacer::-webkit-scrollbar-thumb:hover,
.reason-field-container::-webkit-scrollbar-thumb:hover,
.scroll-bar::-webkit-scrollbar-thumb:hover,
.modal-sub-body::-webkit-scrollbar-thumb:hover,
.form-field-sub-container::-webkit-scrollbar-thumb:hover,
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb:hover,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:hover,
.user-leave-details-main-container::-webkit-scrollbar-thumb:hover,
.payroll-container::-webkit-scrollbar-thumb:hover,
.sub-container-wrapper::-webkit-scrollbar-thumb:hover,
.card-sub-body::-webkit-scrollbar-thumb:hover,
.lop-sub-table-container::-webkit-scrollbar-thumb:hover,
.team-create-form-container::-webkit-scrollbar-thumb:hover,
.work-from-home-sub-container::-webkit-scrollbar-thumb:hover,
.edit-field-container::-webkit-scrollbar-thumb:hover,
.form-field-stack-container::-webkit-scrollbar-thumb:hover,
.sub-from-container::-webkit-scrollbar-thumb:hover,
.form-container-editing::-webkit-scrollbar-thumb:hover,
.attendance-sub-container::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.modal-body textarea::placeholder {
  margin-left: 2px;
}

.modal-body,
.modal-body textarea::placeholder,
.modal-body form input,
.modal-body div label,
.modal-body div div,
.modal-body div li {
  font-size: 14px !important;
}

.ag-horizontal-left-spacer {
  border-right: none !important;
}

/* card style*/
.card-title p {
  font-size: 16px !important;
}



.card-item {
  font-size: 14px !important;
}

/*Code for timer*/
.time-count-container span {
  border-radius: 15px;
  color: var(--primaryColor);
  font-size: 14px !important;
  padding: 1%;
}

.status div {
  font-size: 14px !important;
}

.clock-in-out {
  font-size: 14px !important;
}

.time-count-button-container div {
  font-size: 12px !important;
}

.heading h2 {
  font-size: 18px !important;
}

.dashboard-button-class-top button {
  padding: 3px 12px !important;
}

.leave-review-field-container-no-file {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


@media screen and (min-width:688px) and (max-width:716px) {
  .dashboard-button-class-top button {
    padding: 3px 6px !important;
  }
}

@media screen and (min-width:673px) and (max-width:687px) {
  .dashboard-button-class-top button {
    padding: 3px 2px !important;
  }
}



@media screen and (min-width:600px) and (max-width:1440px) {
  main {
    width: calc(100% - 252px);
    background-color: #fff !important;
  }
}




@media screen and (min-width:700px) and (max-width:900px) {
  .heading h2 {
    font-size: 18px;
  }

  .card-title p {
    font-size: 16px !important;
  }

  .card-item {
    font-size: 14px !important;
  }



}

@media screen and (min-width:600px) and (max-width:699px) {
  .heading h2 {
    font-size: 16px;
  }

  .card-title p {
    font-size: 14px !important;
  }


  .modal-heading h4 {
    font-size: 14px !important;
  }

  .modal-body,
  .modal-body textarea::placeholder,
  .modal-body form input,
  .modal-body div label,
  .modal-body div div,
  .modal-body div li {
    font-size: 12px !important;
  }

}

@media screen and (min-width:427px) and (max-width:599px) {
  .heading h2 {
    font-size: 16px !important;

  }

  .card-title p {
    font-size: 14px !important;
  }

  .card-item div {
    font-size: 12px !important;
  }

  .status div {
    font-size: 12px !important;
  }

  .clock-in-out {
    font-size: 12px !important;
  }


  .modal-heading h4 {
    font-size: 14px !important;
  }

  .modal-body,
  .modal-body textarea::placeholder,
  .modal-body form input,
  .modal-body div label,
  .modal-body div div,
  .modal-body div li {
    font-size: 12px !important;
  }
}

@media screen and (min-width:300px) and (max-width:426px) {
  .heading h2 {
    font-size: 16px !important;

  }

  .card-title p {
    font-size: 14px !important;
  }

  .card-item {
    font-size: 12px !important;
  }

  .status div {
    font-size: 12px !important;
  }

  .clock-in-out {
    font-size: 12px !important;
  }

  .modal-heading h4 {
    font-size: 14px !important;
  }

  .modal-body,
  .modal-body textarea::placeholder,
  .modal-body form input,
  .modal-body div label,
  .modal-body div div,
  .modal-body div li {
    font-size: 12px !important;
  }
}