.edit-icon-style {
  font-size: 18px !important
}

.admin-leave-sub-container thead tbody tr {
  width: 100% !important;
}


.grid-header {
  text-align: center;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-top {
  display: flex;
  width: 100%;
}




.search-input {
  padding: 10px 10px !important;
  height: 0px !important;
}

.leave-search-container {
  /* flex-basis: 50%; */
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  gap: 2px
}

.leave-sub-search-container {
  border: 1px solid;
  padding: 3px 10px 3px 16px;
  border-radius: 50px;
  height: 30px;
  flex-basis: 90%
}

/* .sub-container {
  overflow-x: auto;
} */




.table thead th {
  padding: 5px;
}


@media screen and (min-width:300px) and (max-width:600px) {
  .admin-leave-sub-heading h2 {
    font-size: 18px !important;
  }
}