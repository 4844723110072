.year-month-picker {
    display: flex;
    width: 100%;
    gap: 5px;
}

.table th,
tbody {
    text-align: center;
}

.table thead {
    z-index: 1;
}

.attendance-list-heading {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
}

.attendance-list-heading p {
    font-size: 15px;
}

.salary-slip-search-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 250px;
}

.year-month-picker .MuiInputBase-root {
    font-size: 14px !important;
    text-align: center !important;
    width: 101%;
}


.filter-date-picker {
    display: flex;
}


@media screen and (min-width:300px) and (max-width:480px) {
    .attendance-list-heading h2 {
        font-size: 18px !important;

    }

    .attendance-list-heading {
        height: 58px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .user-lop-date-range-container {
        width: 100%;
    }
}



@media screen and (min-width:481px) and (max-width:600px) {
    .attendance-list-heading h2 {
        font-size: 18px !important;

    }

    .attendance-list-heading {
        height: 32px;
        justify-content: space-between;
        gap: 5px;
        align-items: flex-start;

    }

    .user-lop-date-range-container {
        width: 64% !important;
    }
}

@media screen and (min-width:600px) and (max-width:832px) {
    .attendance-list-heading {
        height: 68px;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }
}


@media screen and (min-width:600px) and (max-width:773px) {
    .salary-slip-search-container {
        flex-basis: 130px !important;
    }

    .date-pickers .MuiInputBase-root {
        font-size: 14px !important;
        text-align: center;
        overflow: hidden !important;
    }
}

@media screen and (min-width:300px) and (max-width:460px) {
    .salary-slip-search-container {
        flex-basis: 60px !important;
    }

    .date-pickers {
        min-height: 40px !important;
    }

    .date-pickers .MuiInputBase-root {
        font-size: 14px !important;
        text-align: center;
        overflow: hidden !important;
    }

    .date-pickers .MuiFormControl-root {
        min-height: 30px !important;
        /* margin-bottom: -10px; */
        /* border: 1px solid red; */
    }
}