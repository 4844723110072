.modal-body .rating-container span {
    font-size: 22px !important;
}

.modal-body .error-container {
    font-size: 10px !important;
    color: red;
}

.rating-container {
    width: 100%;
}