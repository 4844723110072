.accounts-sub-container {
  /* overflow-x: hidden !important; */
  height: calc(100% - 35px) !important;
}

.edit-account-editing {
  font-weight: 600;
  height: 7% !important;
}

.form-container-editing {
  overflow-y: scroll;
  height: calc(100% - 40px);
  padding: 8px;

}

.main-form-container {
  height: calc(100% - 25px) !important;
}

.editing-button-container {
  height: 40px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-button-container {
  height: 35px;
}


@media screen and (min-width:300px) and (max-width:600px) {
  .reset-button {
    font-size: 12px;
  }
}