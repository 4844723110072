/* .sub-container {
    overflow: hidden;

} */

.lop-attendance-heading {
    height: 62px;
    padding-top: 5px;
}

.lop-attendance-sub-heading {
    height: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}



.lop-date-range-container {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    height: 100%;
}

.lop-sub-date-range {
    display: flex;
    gap: 2px
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    height: 100%;
}

.lop-attendance-sub-container {
    height: calc(100% - 62px);
    padding-top: 5px;

}

.mui-table-container {
    max-height: 100%;
    width: 100%
}

.table-cell-container {
    display: flex;
}

.table-cell-container .width25 {
    min-width: 100px !important;
}

.date-range-container {
    justify-content: flex-start;
    align-items: center;
    gap: 2px !important;
}

.lop-download-button-container {
    border-radius: 20px !important;
}

.lop-sub-table-container {
    height: 100%;
    width: 100%;
    overflow: auto !important;
}

.css-rorn0c-MuiTableContainer-root {
    overflow: visible !important;
}

.lop-sub-date-range .css-19nxzat-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

.lop-download-button-main-container {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center
}



.date-field-container {
    height: 38px;
}

.body-width25 {
    width: 25%;
    font-size: 14px;
}

.heading-container {
    display: flex;
    justify-content: center;
    align-items: center
}


.flex-basis {
    flex-basis: 335px;
}


@media screen and (min-width:773px) and (max-width:850px) {
    .flex-basis {
        flex-basis: 515px !important;
    }

}

@media screen and (min-width:460px) and (max-width:575px) {
    .flex-basis {
        flex-basis: 515px !important;
    }

}