.wfh-heading {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wfh-date-container {
    width: 100%;
}

.work-from-home-sub-container {
    overflow-y: auto;
    height: 100% !important;
}

.pending-button-container {
    gap: 2px !important;
}

.wfh-date-range-container {
    display: flex;
    gap: 2px;
}

.work-from-home-sub-container .table button:hover {
    background: var(--primaryColor);
    color: #fff;
    transition: 0.5s;
}


.wfh-table-head1 {
    padding: 10px !important
}

.wfh-table-head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 10px !important
}

.wfh-dates-container {
    height: 58px;
}

@media (max-width:1440px) {
    .wfh-dates-container .css-4jnixx-MuiStack-root {
        height: 49px !important;
        overflow: hidden;
    }

    .wfh-dates-container .css-4jnixx-MuiStack-root>.MuiTextField-root {
        height: 40px;
    }
}

@media screen and (min-width:300px) and (max-width:421px) {

    .wfh-heading h2 {
        font-size: 16px !important;
    }

    .wfh-heading h2 {
        text-align: left;
    }

    .wfh-heading {
        flex-direction: column;
        height: 148px !important;
        justify-content: flex-start !important;
        align-items: flex-start;
        padding-bottom: 20px !important;
    }


    .wfh-date-range-container {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }



    .work-from-home-sub-container {
        height: 100% !important;
    }
}

@media screen and (min-width:422px) and (max-width:600px) {
    .wfh-heading h2 {
        font-size: 16px !important;
    }

    .wfh-heading h2 {
        text-align: left;
    }

    .wfh-heading {
        flex-direction: column;
        height: 80px !important;
        justify-content: flex-start !important;
        align-items: flex-start;
        padding-bottom: 20px !important;
    }


    .wfh-date-range-container {
        width: 100%;
    }



    .work-from-home-sub-container {
        height: 100% !important;
    }
}

@media screen and (min-width:600px) and (max-width:779px) {
    .wfh-heading h2 {
        text-align: left;
    }

    .wfh-heading {
        flex-direction: column;
        height: 93px !important;
        justify-content: flex-start !important;
        align-items: flex-start;
        padding-bottom: 20px !important;
    }


    .wfh-date-range-container {
        width: 100%;
    }



    .work-from-home-sub-container {
        height: 100% !important;
    }
}



@media screen and (min-width:779px) and (max-width:984px) {
    .wfh-heading {
        height: 93px !important;
        justify-content: flex-start !important;
        flex-direction: column;
        align-items: flex-start;
    }



    .work-from-home-sub-container {
        height: 100% !important;
    }
}