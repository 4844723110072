.payroll-title {
    font-size: 130%;
    color: var(--primaryColor);
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
    height: 5%;
}

.payroll-container {
    margin-top: 10px;
    height: 95%;
    overflow: auto;
    margin-right: 2px;
}

.payroll-sub-container {
    border: 1px solid #d4cdcd;
    border-radius: 5px;
    padding: 0px 0px 0px 40px;
}

.payroll-amount-container {
    width: 120px
}

.payroll-amount-container2 {
    width: 120px;
    font-weight: 900;
}

.payroll-main-container {
    height: 93%;
    margin-top: 6px;
}


.payroll-sub-container {
    overflow-y: auto;
}

.payroll-field-container {
    margin-top: 20px;
}

.payroll-field-sub-container {
    display: flex;
    gap: 3%;
    color: var(--primaryColor);
    margin-top: 2%;
    width: 100%;
}

.payroll-field-sub-arrow-container {
    color: var(--primaryColor)
}

.payroll-details-container {
    /* border: 1px solid rgb(167, 160, 160); */
    width: "100%"
}

.payroll-details-heading {
    margin-left: 6%;
    margin-top: 10px
}

.payroll-details-sub-container {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    flex-wrap: wrap;
    margin-left: 6%;
}

.payroll-field-details-sub-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.payroll-sub-details-container {
    display: flex;
    gap: 50%;
    margin-left: 6%;
    flex-wrap: wrap;
}

.payroll-amount-container {
    width: 120px;
}

.payroll-amount-container1 {
    width: 145px;
}