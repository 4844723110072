.form-field-container {
    height: calc(100% - 45px);
}

.form-button-container {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox-container .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 12px !important;
    margin-top: 10px;
}