.ag-theme-alpine {
  /* --ag-header-foreground-color: var(--tertiarycolor) !important; */
  --ag-header-background-color: var(--primaryColor) !important;
}

.delete-employee-button {
  color: var(--dangerColor);
}

.edit-employee-button {
  color: var(--primaryColor) !important;
}



.back-button {
  color: var(--primaryColor);
  font-size: 150% !important;
}

.employee-heading-container {
  height: 10%
}

.employee-sub-heading-container {
  padding: 0px 40px 0px 0px
}

.back-arrow {
  position: relative;
  margin-left: -33px !important;
}

.sub-heading-container {
  gap: 7px !important;
}

.employee-sub-heading-add-item {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.employee-sub-container {
  height: calc(100% - 30px) !important;
  margin-bottom: 4px;
}



@media (max-width:478px) {}

@media screen and (min-width:448px) and (max-width:600px) {


  .back-arrow {
    margin-left: -28px !important;
  }
}

@media screen and (min-width:300px) and (max-width:447px) {


  .back-arrow {
    margin-left: -25px !important;
  }
}