.select-type-container {
    display: flex !important;
    flex-direction: column !important;
}

.select-type-container fieldset {
    top: -11px;
}

.modal-body input {
    margin-bottom: 4px;
}

.modal-body form div div div {
    margin-bottom: 2px;
}


.team-create-container {
    font-size: 14px;
    height: fit-content;
}

.team-heading {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.team-buttom-container {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center
}

.team-create-form-container {
    max-height: calc(350px - 45px);
    overflow-y: auto;
    min-height: 100px;
    padding-right: 20px;
}


.team-form-container-label {
    font-size: 16px;
    font-weight: 700;
}

.team-create-close-button {
    margin-right: 4px;
}

.select-input-field {
    height: 40px;
}

.select-menu-field {
    height: 20px;
}

@media screen and (max-width: 380px) {
    .team-create-sub-container.heading1 h2 {
        font-size: 100%;
    }



}

.MuiAutocomplete-tag {
    background-color: rgb(236, 227, 227) !important;
    border-radius: 20px !important;
}

.MuiAutocomplete-input {
    padding: 0 !important;
}