.leave-application-form-container {
    height: 100%;
    position: relative
}

.leave-application-heading {
    height: 30px;
    padding: 5px;
    display: flex;
    justify-content: space-between
}


.leave-application-field-container {
    height: 251px;
    padding-right: 4px;
    overflow-y: auto;
    overflow-x: hidden;
}

.fileuplod-field-main-container {
    display: flex;
    width: 100%;
    position: relative;
    height: 60px
}

.fileuplod-field-container {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px
}

.fileuplod-file-label-container {
    font-size: 12px;
    margin-top: 10%;
    width: 150px
}

.leave-application-button-container {
    text-align: center;
    padding-top: 5px;
    height: calc(100% - 252px) !important
}



.leave-application-field-container .css-4jnixx-MuiStack-root {
    flex-direction: column !important;
}

.date-container {
    width: 100%;
    /* height: 50px; */
}


/* @media screen and (min-width:300px) and (max-width:426px) {
    .leave-application-heading h4 {
        font-size: 15px !important;
    }



}

@media screen and (min-width:600px) and (max-width:1024px) {
    .leave-application-heading h4 {
        font-size: 20px !important;
    }


}

@media screen and (min-width:427px) and (max-width:600px) {
    .leave-application-heading h4 {
        font-size: 18px !important;
    }
} */