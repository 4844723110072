.holiday-sub-container {
  overflow-y: auto;
  height: calc(100% - 35px);
  /* border: 1px solid red */
}

.holiday-list-sub-container {
  margin: 0;
  display: flex;
  width: 100%;
  padding-left: 25%;
  padding-Right: 25%;
  justify-content: center;
}


/* Boilerplate stuff */

.holiday-list-container {
  height: 100%;
  /* display: flex; */
  /* justify-content: center; */
}

.table-credits {
  font-size: 12px;
  margin-top: 10px;
}

/* FOOTER STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  z-index: 1;
  background: var(--white);
}

.page-footer a {
  display: flex;
  margin-left: 4px;
}